import React, { useEffect, useState } from "react";
import moment from "moment";

import Moment from "react-moment";

import {
  DonationsBricksWrapper,
  StyledDonationBrick,
} from "./defaultBricksStyle";

import { BrickRowWrapper, BufferBrick } from "./brickRowsStyle";

interface Props {
  rowColumns?: Array<any>;
  rowColumnsIpad?: Array<any>;
}

interface DonationInfoProps {
  brickColour: string;
  amount: number;
  brick: any;
  dateString: string;
  isVisible: boolean;
  width: number;
}

const BrickRow = (props: Props) => {
  const { rowColumns } = props;
  const addConvertedDate = rowColumns?.map(brick => {
    const dateSort = moment(brick.date, "DD/MM/YYYY").toDate();
    return {
      ...brick,
      dateSort,
    };
  });
  const result = addConvertedDate?.sort((a, b) =>
    a.dateSort < b.dateSort ? 1 : -1
  );
  const DonationInfo = ({
    brick,
    brickColour,
    amount,
    dateString,
    isVisible,
    width,
  }: DonationInfoProps) => {
    return (
      brick.isVisible && (
        <StyledDonationBrick color={brickColour} width={width}>
          <div className="donation-value">
            <p>${amount}</p>
          </div>
          <div className="brick-text">
            <p>{brick.brickName}</p>
            <p>{brick.date}</p>
          </div>
        </StyledDonationBrick>
      )
    );
  };

  return (
    <div>
      <BrickRowWrapper>
        {result?.map((brick, index) => {
          const finalAmount = Number(brick.brickAmount);
          var brickColour = getColour(finalAmount);
          if (brick.brickAmount >= 250) {
            return (
              <DonationInfo
                amount={finalAmount}
                brick={brick}
                dateString={brick.date}
                brickColour={brickColour}
                width={brick.width}
                isVisible={brick.isVisible}
                key={index}
              />
            );
          }
        })}
      </BrickRowWrapper>
    </div>
  );
};

export default BrickRow;

function getColour(amount: number) {
  var brickColour = "#000";

  amount >= 250 && amount < 500
    ? (brickColour = "#E61928")
    : amount >= 500 && amount < 1000
    ? (brickColour = "#004696")
    : amount >= 1000 && amount < 2000
    ? (brickColour = "#00A032")
    : amount >= 2000
    ? (brickColour = "#CEA515")
    : (brickColour = "#004696");
  return brickColour;
}
