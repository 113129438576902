import React from "react";
import IntroSection from "../donate/introStyle";
import { H2, P } from "../../util/standard";
import BlockContent from "@sanity/block-content-to-react";
import ArrowLink from "../shared/sub/ArrowLink";
import { Link } from "@util/types";
import { isBrowser } from "@util/hooks";

interface Props {
  donateContent?: any;
  marginOveride?: string;
  links?: Link;
  subtitle?: string;
  noArrowLink?: boolean;
}

const BrickDonate = (props: Props) => {
  const content = props.donateContent;
  const { marginOveride, links, subtitle, noArrowLink } = props;

  const scrollToDonate = () => {
    if (isBrowser()) {
      const buyABrick = document.getElementById("buy-a-brick");
      buyABrick?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <IntroSection margin={marginOveride}>
      <P>{subtitle}</P>
      <H2>{content.title}</H2>
      <BlockContent blocks={content.description._rawContent}></BlockContent>
      {links && !noArrowLink && (
        <ArrowLink onClick={() => scrollToDonate()} link={links} />
      )}
    </IntroSection>
  );
};

export default BrickDonate;
