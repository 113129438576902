import styled from "styled-components";
import {
  MOBILE_BREAKPOINT,
  IPAD_BREAKPOINT,
  TABLET_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
} from "../../util/constants";

export const BricksGridWrapper = styled.div`
  width: 80%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding: 0 5px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: block;
    padding: 0px;
  }
`;

export const StyledBrick = styled.div<{
  color?: String;
  isPopulated?: Boolean;
}>`
  border: 2px solid ${props => props.color ?? "#000"};
  box-sizing: border-box;
  border-radius: 10px;
  width: 20%;
  height: 72px;

  display: flex;
  margin-left: 5px;
  margin-right: 5px;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;

    &:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    height: 104px;

    &:nth-child(1) {
      height: 72px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 10px;
    flex-direction: row;
    height: 72px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .donation-value {
    color: ${props => props.color ?? "#000"};

    font-family: "bold";
    font-size: 22px;
    line-height: 32px;
    display: flex;
    width: 50%;

    p {
      margin: auto;
    }

    @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
      width: 100%;
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      margin: auto auto 0;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      margin: auto;
    }
  }

  .brick-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: ${props => props.color ?? "#000"};

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }

    @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
      width: 100%;
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      margin: 0 auto auto;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      margin: auto;
    }
  }

  .no-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "bold";
    width: 100%;
    text-align: center;

    p {
      width: 100%;
      margin-bottom: 0px;
    }

    a {
      color: #00a032;
      text-decoration: underline;
      font-family: "regular";
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      margin: auto;
    }
  }
`;

export const DonationsBricksWrapper = styled.div`
  width: 80%;
  margin: auto;
  /* display: grid; */
  grid-template-columns: repeat(5, auto);
  margin-top: 5px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const StyledDonationBrick = styled.div<{
  color?: String;
  isPopulated?: Boolean;
  width?: number;
}>`
  border: 2px solid ${props => props.color ?? "#000"};
  box-sizing: border-box;
  border-radius: 10px;
  ${({ width }) => width && `width: ${width}px;`};
  height: 72px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    height: 104px;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }

  display: flex;
  margin: 5px;

  background-color: ${props => props.color ?? "#000"};

  .donation-value {
    color: #fff;
    font-family: "bold";
    font-size: 22px;
    line-height: 32px;
    display: flex;
    width: auto;
    margin: auto;

    p {
      margin: auto;
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      margin: auto auto 0;
    }
  }

  .brick-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: auto;
    color: #fff;

    p {
      margin-bottom: 0;
      font-size: 14px !important;
      line-height: 21px;
      text-align: center;
    }

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      margin: 0 auto auto;
    }
  }

  .no-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "bold";
    width: 100%;
    text-align: center;

    p {
      width: 100%;
      margin-bottom: 0px;
    }

    a {
      color: #00a032;
      text-decoration: underline;
      font-family: "regular";
    }
  }
`;

export const LoadingIndicator = styled.img`
  display: block;
  margin: 0 auto;
  width: 50px;
  padding-top: 50px;
`;
