import { useStaticQuery, graphql, navigate } from "gatsby";
import React from "react";

import { BricksGridWrapper, StyledBrick } from "./defaultBricksStyle";

interface Props {
  buttonOnClick?: () => void;
}

const DefaultBricks = (props: Props) => {
  const { buttonOnClick } = props;

  const {
    sanityDonationBricks: { defaultBricks: bricks },
  } = useStaticQuery(graphql`
    query {
      sanityDonationBricks {
        defaultBricks {
          donationValue
          colorlist {
            value
            title
          }
          firstLine
          secondLine
          _key
        }
      }
    }
  `);

  return (
    <BricksGridWrapper>
      {bricks.map(brick => {
        return (
          <StyledBrick color={brick.colorlist.value ?? "#000"} key={brick._key}>
            {brick.donationValue ? (
              <>
                <div className="donation-value">
                  <p>${brick.donationValue}</p>
                </div>
                <div className="brick-text">
                  <p>{brick.firstLine}</p>
                  <p>{brick.secondLine}</p>
                </div>
              </>
            ) : (
              <div className="no-value">
                <p>{brick.firstLine}</p>
                <a
                  onClick={
                    buttonOnClick ? buttonOnClick : () => navigate("/donate")
                  }
                >
                  Donate Now
                </a>
              </div>
            )}
          </StyledBrick>
        );
      })}
    </BricksGridWrapper>
  );
};

export default DefaultBricks;
