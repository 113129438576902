import React, { useEffect, useState } from "react";

import { DonationsBricksWrapper, LoadingIndicator } from "./defaultBricksStyle";
import moment from "moment";
import BrickRow from "./brickRows";
import { assets } from "../../util/constants";

const axios = require("axios");

function DonationBricks() {
  useEffect(() => {
    if (donationData === null) {
      writeData();
    }
  });

  const [donationData, updateDonationData] = useState(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  // const { sanityDonationData } = useStaticQuery(graphql`
  //   {
  //     sanityDonationData {
  //       donationFile {
  //         _type
  //         asset {
  //           url
  //         }
  //       }
  //     }
  //   }
  // `);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const writeData = () => {
    // const url = sanityDonationData.donationFile.asset.url;
    axios
      .get("/.netlify/functions/donationdata")
      .then(function (response) {
        if (response.data.data) {
          updateDonationData(response.data.data);
          setContentLoaded(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Loading = () => {
    return loading ? <LoadingIndicator src={assets.loadingGif} /> : null;
  };

  return (
    <>
      {!donationData && !contentLoaded && <Loading />}
      {donationData && contentLoaded && (
        <>
          <DonationsBricksWrapper>
            <BrickRow rowColumns={donationData} />
          </DonationsBricksWrapper>
          <DonationsBricksWrapper>
            <BrickRow rowColumnsIpad={donationData} />
          </DonationsBricksWrapper>
        </>
      )}
    </>
  );
}

export default DonationBricks;
