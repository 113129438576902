import { SanityEvents } from "@graphql-types";
import { useEffect, useState } from "react";

export const useSplitEventsByDate = (events: SanityEvents[] | null) => {
  if (events == null) return null;

  const [futureEvents, setFutureEvents] = useState<SanityEvents[]>();
  const [pastEvents, setPastEvents] = useState<SanityEvents[]>();

  useEffect(() => {
    const date = new Date().toISOString();

    setFutureEvents(
      events.filter(event => {
        return event.eventDate > date;
      })
    );

    setPastEvents(events.filter(event => event.eventDate < date));
  }, []);

  return { futureEvents, pastEvents };
};

//To solve serverside rendering issue
export const isBrowser = () => typeof window !== "undefined";
