import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import DonateIntro from "../components/donate/intro";
import Payment from "../components/donate/payment";
import InfoBlock from "../components/donate/infoBlock";
import ImpactBlock from "../components/shared/ImpactBlock";
import SEO from "../components/shared/Seo";
import Hero from "../components/shared/Hero";
import BrickDonate from "../components/buyABrick/brickDonate";
import DonationBricks from "../components/bricks/bricks";
import DefaultBricks from "../components/bricks/defaultBricks";
import { isBrowser } from "@util/hooks";

const DonatePage = () => {
  const { sanityBuyBrick } = useStaticQuery(graphql`
    query {
      sanityBuyBrick {
        seoBlock {
          pageDescription
          pageKeyWords
          pageTitle
          ogImage {
            asset {
              url
            }
          }
        }
        buyBrickHero {
          heroText
          heroImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
              title
            }
          }
        }
        buyBrickIntro {
          title
          description {
            _key
            _type
            _rawContent
          }
        }
        brickPreviewDeskImg {
          asset {
            fluid(maxWidth: 1440) {
              srcSet
              src
              ...GatsbySanityImageFluid
            }
          }
        }
        brickPreviewMobileImg {
          asset {
            fluid(maxWidth: 1440) {
              srcSet
              src
              ...GatsbySanityImageFluid
            }
          }
        }
        brickPreviewTabletImg {
          asset {
            fluid(maxWidth: 1440) {
              srcSet
              src
              ...GatsbySanityImageFluid
            }
          }
        }
        buyBrickInfoBlock {
          title
          subTitle
          description {
            _key
            _type
            _rawContent
          }
          link {
            url
            linkText
          }
        }
      }
    }
  `);

  const { heroText, heroImage } = sanityBuyBrick.buyBrickHero;

  const scrollToDonate = () => {
    if (isBrowser()) {
      const buyABrick = document.getElementById("buy-a-brick");
      buyABrick?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div>
      <SEO seoData={sanityBuyBrick?.seoBlock} slug="donate" />
      <Hero heroText={heroText} heroImage={heroImage} />
      <BrickDonate
        donateContent={sanityBuyBrick.buyBrickInfoBlock}
        links={sanityBuyBrick.buyBrickInfoBlock.link}
        subtitle={sanityBuyBrick.buyBrickInfoBlock.subTitle}
      />
      <DefaultBricks buttonOnClick={() => scrollToDonate()} />
      <DonationBricks />
      <DonateIntro introContent={sanityBuyBrick.buyBrickIntro} noArrowLink />
      <Payment requestedPage="bricks" />
      <InfoBlock />
      <ImpactBlock />
    </div>
  );
};

export default DonatePage;
