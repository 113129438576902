import styled from "styled-components"
import {
  SMALL_LAPTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../util/constants"

export const BrickRowWrapper = styled.div<{
  isIpad?: boolean
  isMobile?: boolean
}>`
  margin: auto;
  max-width: 1440px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const BufferBrick = styled.div`
  width: 10%;
  height: auto;
  background-color: #00a032;
  margin: 5px;
  border-radius: 10px;
`
